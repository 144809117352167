<template>
    <form method="post" @submit.prevent="() => {}">
        <div class="form-group m-0 search-head pl-lg-2">
            <input
                type="text"
                class="form-control font-sub-head"
                placeholder="Search for Services, Health Packages"
                @input="updateSearch"
                @focus="state.focus = true"
                :value="state.search"
                @blur="blurHandler"
                :disabled="state.submitting"
                :class="[
                    !state.submitting &&
                    vd.search.$model &&
                    vd.search.$model.length &&
                    vd.search.$error
                        ? 'is-invalid'
                        : '',
                ]"
            />
            <img
                v-if="!state.focus"
                class="pos-abs d-none d-md-block"
                :src="asset('img/search.png')"
            />
            <img
                v-else-if="state.focus"
                @click.prevent="clearSearch"
                class="pos-abs d-none d-md-block"
                :src="asset('img/search-result-close.png')"
            />
            <div
                class="search-results"
                v-if="
                    !!Object.keys(searchResults).length &&
                    !!state.search &&
                    !!state.search.length
                "
            >
                <div
                    class="d-flex justify-content-between pb-1"
                    v-if="searchResults.services.length"
                >
                    <div><h3 class="f-17 clrGreenDark2">Services</h3></div>
                </div>
                <div
                    class="d-flex flex-column"
                    v-for="service in searchResults.services"
                    v-if="searchResults.services.length"
                >
                    <div class="search-result-details">
                        <a :href="getServiceProfileLink(service)">
                            <p class="f-15 mb-0 clrBlueDark proxima-nova-bold">
                                {{ service.ServiceName }}
                                <span
                                    class="f-12 clrGreyDark proxima-nova-reg pl-2 d-inline-block"
                                    >in Services</span
                                >
                            </p>
                        </a>
                    </div>
                </div>
                <div
                    class="d-flex justify-content-between pb-1 pt-2"
                    v-if="searchResults.packages.length"
                >
                    <div><h3 class="f-17 clrGreenDark2">Packages</h3></div>
                </div>
                <div
                    class="d-flex flex-column"
                    v-for="wellnessPackage in searchResults.packages"
                    v-if="searchResults.packages.length"
                >
                    <div class="search-result-details">
                        <a :href="getPackageProfileLink(wellnessPackage)">
                            <p class="f-15 mb-0 clrBlueDark proxima-nova-bold">
                                {{ wellnessPackage.Wellnesspackagename }}
                                <span
                                    class="f-12 clrGreyDark proxima-nova-reg pl-2 d-inline-block"
                                    >in Packages</span
                                >
                            </p>
                        </a>
                    </div>
                </div>
                <div
                    class="d-flex justify-content-between pb-1 pt-2"
                    v-if="searchResults.tests.length"
                >
                    <div><h3 class="f-17 clrGreenDark2">Tests</h3></div>
                </div>
                <div
                    class="d-flex flex-column"
                    v-for="test in searchResults.tests"
                    v-if="searchResults.tests.length"
                >
                    <div class="search-result-details">
                        <a :href="getItemProfileLink(test)">
                            <p class="f-15 mb-0 clrBlueDark proxima-nova-bold">
                                {{ test.ItemName }}
                                <span
                                    class="f-12 clrGreyDark proxima-nova-reg pl-2 d-inline-block"
                                    >in Tests</span
                                >
                            </p>
                        </a>
                    </div>
                </div>
            </div>
            <div
                class="search-results"
                v-else-if="!!Object.keys(populars).length && state.focus"
            >
                <div class="d-flex justify-content-between pb-1 pt-2">
                    <div>
                        <h3 class="f-17 clrGreenDark2">Popular Services</h3>
                    </div>
                </div>
                <div
                    class="d-flex flex-column"
                    v-for="service in populars.services"
                    v-if="populars.services.length"
                >
                    <div class="search-result-details">
                        <a :href="getServiceProfileLink(service)">
                            <p class="f-15 mb-0 clrBlueDark proxima-nova-bold">
                                {{ service.ServiceName }}
                                <span
                                    class="f-12 clrGreyDark proxima-nova-reg pl-2 d-inline-block"
                                    >in Services</span
                                >
                            </p>
                        </a>
                    </div>
                </div>
                <div class="d-flex justify-content-between pb-1">
                    <div>
                        <h3 class="f-17 clrGreenDark2">
                            Best Selling Wellness Profile
                        </h3>
                    </div>
                </div>
                <div
                    class="d-flex flex-column"
                    v-for="wellnessPackage in populars.packages"
                    v-if="populars.packages.length"
                >
                    <div class="search-result-details">
                        <a :href="getPackageProfileLink(wellnessPackage)">
                            <p class="f-15 mb-0 clrBlueDark proxima-nova-bold">
                                {{ wellnessPackage.Wellnesspackagename }}
                                <span
                                    class="f-12 clrGreyDark proxima-nova-reg pl-2 d-inline-block"
                                    >in Packages</span
                                >
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export { default } from "./Controller";
</script>

<style lang="scss" scoped>
.search-head {
    img {
        right: 32px;
        top: 8px;
        z-index: 1;
        cursor: pointer;
    }
}

.is-valid {
    background-color: #28a74515 !important;
    border: 1px solid #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-image: none;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    --border-hover: 1px solid #28a745 !important;
    --border-focus: 1px solid #28a745 !important;
    &.n-input--focus {
        box-shadow: 0 0 0 0.2rem rgb(40 167 69 / 10%);
    }
}
.is-invalid {
    background-color: #dc354515 !important;
    border: 1px solid #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: none;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    --border-hover: 1px solid #dc3545 !important;
    --border-focus: 1px solid #dc3545 !important;
    &.n-input--focus {
        box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 10%);
    }
}

.search-results {
    border: 1px solid #ced4da;
    border-radius: 0 0 8px 8px;
    padding: 20px 0.75rem 0;
    position: absolute;
    border-top: 0;
    background-color: #fff;
    z-index: 2;
    top: 38px;
    left: 23px;
    right: 15px;
    max-height: 70vh;
    overflow-y: auto;

    .search-result-details {
        border-bottom: 1px solid #dde6ef;
        padding-bottom: 10px;
        margin-bottom: 10px;
        &:last-child {
            border: none;
            margin-bottom: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .search-results {
        left: 15px;
        margin-top: 100px;
    }
}
</style>
