const { appOrigin } = require("@settings");
const fetchSearchResultsApi = `${appOrigin}/api/fetch-search-results`;
const fetchPopularsApi = `${appOrigin}/api/fetch-populars`;
module.exports = {
    getServiceProfileLink: (service) => `${appOrigin}/service/${service.Slug}`,
    getPackageProfileLink: (wellnessPackage) =>
        `${appOrigin}/package/${wellnessPackage.Slug}`,
    getItemProfileLink: (test) =>
        // `${appOrigin}/services/${test.ServiceId}/tests/${test.ItemSeqID}`,
        `${appOrigin}/services/pathology/tests/${test.Slug}`,
    fetchSearchResultsApi,
    fetchPopularsApi,
};
